// import BoardSearchBar from '@/components/BoardSearchBar';
import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import Container from '@/components/Container';
import Row from '@/components/Grid/Row';
import NewsBannerList from '@/components/News/NewsBannerList';
import NewsItem, {
  NewsItemList,
  SectionContainer,
} from '@/components/News/NewsItem';
import { News } from '@/components/News/types';
import NewsBoardSearchBar from '@/components/NewsBoardSearchBar';
import TagPagination from '@/components/Pagination/TagPagination';
import { Tit } from '@/components/Titles';
import {
  BOARD_KIND_NEWS,
  BOARD_KIND_PUBLIC,
  BOARD_KIND_STORY,
  BOARD_KIND_FIELDTRIP,
  BOARD_KIND_INTERVIEW,
} from '@/helpers/BoardHelper';
import { getCurrentPageInfo, PageInfo } from '@/helpers/PaginationHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { navigate, PageProps } from 'gatsby';
import queryString from 'query-string';
import React, { FC, useCallback, useEffect, useState } from 'react';

const NewsPage: FC<PageProps> = ({ location }) => {
  const qs = queryString.parse(location.search);
  // 검색중일때 현재 페이지 데이터
  const searchCurrentPage = qs.currentPage ? Number(qs.currentPage) : 1;
  const keyword = qs.keyword as string;
  const category = qs.category as string;

  // 기본데이터는 빈배열 (조건에 따라 로드되기 때문)
  const [nodes, setNodes] = useState<News[]>([]);
  const [banners, setBanners] = useState<News[]>([]);
  const [currentPageInfo, setCurrentPageInfo] = useState<PageInfo>();
  const [searchKey, setSearchKey] = useState(``);
  const [searchLoading, setSearchLoading] = useState(false);
  const handleSearchKey = (key: string) => {
    setSearchKey(`#${key}`);
  };

  // 배너 로드
  const loadBanners = useCallback(async () => {
    try {
      const { data } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: [
          BOARD_KIND_NEWS,
          BOARD_KIND_PUBLIC,
          BOARD_KIND_STORY,
          BOARD_KIND_FIELDTRIP,
          BOARD_KIND_INTERVIEW,
        ],
        page: 1,
        // 데이터가 너무 많아서 임시로 20개 지정
        pagePerCount: 20,
      });
      const banner = data as any;
      setBanners(
        banner.filter((item: BoardVo) => item.newsMainYn === 'Y').slice(0, 3),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  // 소식 로드
  const loadNews = useCallback(async () => {
    try {
      const {
        totalCount,
        data,
      } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: [
          BOARD_KIND_NEWS,
          BOARD_KIND_PUBLIC,
          BOARD_KIND_STORY,
          BOARD_KIND_FIELDTRIP,
          BOARD_KIND_INTERVIEW,
        ],
        page: 1,
        pagePerCount: 6,
        postOrderOption: 'Y',
      });

      // 페이지네이션 정보
      const newsTotalCount = totalCount as any;
      const pageInfo = getCurrentPageInfo({
        totalItemCount: newsTotalCount,
        baseUri: '/what-we-do/news',
        pageGroupSize: 5,
        currentPage: 1,
        itemsPerPage: 6,
      });
      setCurrentPageInfo(pageInfo);

      // 소식 목록
      const newsList = data as any;
      setNodes(newsList);
    } catch (e) {
      console.error(e);
    }
  }, []);

  // 동적 데이터 로드
  const loadPage = useCallback(
    async (
      targetPage: number,
      targetKeyword?: string,
      targetCategory?: string,
    ) => {
      setSearchLoading(true);
      const {
        resultCode,
        data: _data,
        totalCount,
      } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: [
          BOARD_KIND_NEWS,
          BOARD_KIND_PUBLIC,
          BOARD_KIND_STORY,
          BOARD_KIND_FIELDTRIP,
          BOARD_KIND_INTERVIEW,
        ],
        keyword: targetKeyword,
        category: targetCategory,
        page: targetPage,
        pagePerCount: 6,
        postOrderOption: 'Y',
      });

      const newData = _data as any;

      if (resultCode === `success`) {
        const boardPageInfo = getCurrentPageInfo({
          totalItemCount: totalCount || 0,
          currentPage: targetPage,
          pageGroupSize: 5,
          itemsPerPage: 6,
          baseUri: '/what-we-do/news',
          keyword: targetKeyword,
          category: targetCategory,
        });

        setNodes(newData);
        setCurrentPageInfo(boardPageInfo);
      }
      setSearchLoading(false);
    },
    [],
  );

  useEffect(() => {
    // keyword 변경되면 업데이트
    if (keyword || category) {
      loadPage(searchCurrentPage, keyword, category);
    } else {
      loadNews();
    }
    loadBanners();
  }, [searchCurrentPage, keyword, category, loadPage, loadNews, loadBanners]);

  return (
    <LayoutWithTitle
      location={location}
      title="소식"
      description="for every child, news"
    >
      <SectionContainer className="sec1 m-hide">
        <h2 className="a11y">게시글</h2>
        {banners.length > 0 && (
          <Container>
            <NewsBannerList
              banners={banners}
              basePath={currentPageInfo?.baseUri}
              // queryString={location.search}
            />
          </Container>
        )}
      </SectionContainer>

      <SectionContainer className="sec2" id="newsSection">
        <Container>
          <NewsBoardSearchBar
            searchKey={searchKey}
            initialKeyword={keyword}
            initialCategory={category}
            onSearch={(value) => {
              navigate(
                `${currentPageInfo?.baseUri}?${queryString.stringify({
                  keyword: value,
                })}#newsSection`,
              );
            }}
          />

          {nodes.length !== 0 ? (
            <>
              <NewsItemList>
                {nodes.map((item) => (
                  <NewsItem
                    handleSearchKey={handleSearchKey}
                    basePath={currentPageInfo?.baseUri}
                    queryString={location.search}
                    news={item}
                    key={item.boardIndexNumber}
                    initialCategory={category}
                    onSearch={(value) => {
                      navigate(
                        `${currentPageInfo?.baseUri}?${queryString.stringify({
                          category: value,
                        })}#newsSection`,
                      );
                    }}
                  />
                ))}
              </NewsItemList>
              <Row
                justifyContent="center"
                css={`
                  margin-top: 64px;
                `}
              >
                <TagPagination {...currentPageInfo} anchor="#newsSection" />
              </Row>
            </>
          ) : (
            <NewsItemList>
              <Row
                justifyContent="center"
                css={`
                  margin-top: 64px;
                `}
              >
                <Tit size="s4-1" color="gray" className="dec">
                  {searchLoading ? '로딩중...' : '검색 결과가 없습니다'}
                </Tit>
              </Row>
            </NewsItemList>
          )}
        </Container>
      </SectionContainer>
    </LayoutWithTitle>
  );
};

export default NewsPage;
